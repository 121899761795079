<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model="value"
              placeholder="请选择标签信息"
              @change="handleNodeClick(value)"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button size="medium" @click="lookup()">查询</el-button>
          </el-form-item>
          <div style="float: right">
            <el-form-item>
              <el-button type="primary" @click="dialog()" size="medium"
                >重打标签</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="privateData"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-key="getRowKeys"
      v-loading="tableLoading"
    >
      <!--<el-table-column
        type="selection"
        :reserve-selection="true"
        width="55"
        align="center"
      ></el-table-column>-->
      <el-table-column type="index" label="序号" width="60" :index="indexMethod" align="center" />
      <el-table-column
        prop="pvip_name"
        label="会员名称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="pvip_nickname"
        label="微信昵称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="pvip_level"
        label="会员等级"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column> -->
      <el-table-column
        prop="pvip_phone"
        label="会员手机号"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column label="注册时间" width="150" align="center">
        <template slot-scope="scope">
          {{scope.row.pvip_op_dm ? scope.row.pvip_op_dm.replaceAll('T', ' ') : '' }}
        </template>
      </el-table-column>
      <el-table-column label="标签时间" width="150" align="center">
        <template slot-scope="scope">
          {{scope.row.mer_new_datetime ? scope.row.mer_new_datetime.replaceAll('T', ' ') : '' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="mer_label_name"
        label="频次"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="soli_crea_pers"
        label="标签创建人"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10,20,50,100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!--重打标签对话框-->
    <el-dialog :title="frName" :visible.sync="dialogVisible" width="1200px" class="cdbq-dialog">
      <div style="height: 20px;padding-left: 10px;">
        已选择&nbsp;<span style="color: red">{{checkedAll ? soliTotal : checkList.length}}</span>&nbsp;项
      </div>
      <div>
        <el-table
                ref="multipleTables"
                :data="tableData"
                tooltip-effect="dark"
                border
                style="width: 100%"
                stripe
                size="small"
                :row-style="{ height: '10px' }"
                :cell-style="{ padding: '1px 0' }"
                @selection-change="handleSelectionChange"
                @select="selectItem"
                @select-all="selectAll"
                v-loading="cdbqLoading"
        >
          <!--<el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>-->
          <el-table-column prop="id" fixed align="center">
            <el-table-column type="selection" width="75" :selectable="selectable"></el-table-column>
            <template slot="header">
              <div>
                <el-checkbox v-model="checkedAll" @change="changeCheckAll" :disabled="tableData.length === 0">所有</el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" width="60" :index="indexCdbqMethod" align="center" />
          <el-table-column
                  prop="cuid"
                  label="会员id"
                  show-overflow-tooltip
                  align="center"
          >
          </el-table-column>
          <el-table-column
                  prop="pvip_name"
                  label="会员姓名"
                  show-overflow-tooltip
                  align="center"
          >
          </el-table-column>
          <el-table-column
                  prop="pvip_nickname"
                  label="微信昵称"
                  show-overflow-tooltip
                  align="center"
          >
          </el-table-column
          >
          <!--<el-table-column
                  prop="pvip_level"
                  label="会员等级"
                  show-overflow-tooltip
                  align="center"
          >
          </el-table-column>-->
          <el-table-column
                  prop="pvip_phone"
                  label="手机号"
                  show-overflow-tooltip
                  align="center"
                  width="120"
          >
          </el-table-column>
          <el-table-column label="注册时间" width="160" align="center">
            <template slot-scope="scope">
              {{scope.row.pvip_op_dm ? scope.row.pvip_op_dm.replaceAll('T', ' ') : '' }}
            </template>
          </el-table-column>
          <!--<el-table-column
                  prop="pvip_public"
                  label="关注公众号"
                  show-overflow-tooltip
                  align="center"
          >
          </el-table-column>-->
          <el-table-column
                  prop="orde_nun"
                  label="消费频次"
                  show-overflow-tooltip
                  align="center"
          >
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
                  background
                  @size-change="soliHandleSizeChange"
                  @current-change="soliHandleCurrentChange"
                  :current-page="soliCurrent"
                  :page-sizes="[10,20,50,100]"
                  :page-size="soliSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="soliTotal"
          ></el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :disabled="choiceDisabled"
          @click="onSubmit('searchForm')"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

  <script>
import api from "../../api/solidifiaction";
export default {
  name: "MemberList",
  data() {
    return {
      checkedAll: false,
      cdbqLoading: false,
      tableLoading: false,
      searchForm: {},
      delBtlStatu: true,
      choiceDisabled: true, //选择群发
      total: 10,
      size: 10,
      current: 1,
      soliTotal: 10,
      soliSize: 10,
      soliCurrent: 1,
      tableData: [],
      privateData: [],
      checkList:[],
      multipleSelection: [], //选中、取消选中
      options: [
        {
          value: "高频会员",
          label: "高频会员",
        },
        {
          value: "低频会员",
          label: "低频会员",
        },
        {
          value: "睡眠会员",
          label: "睡眠会员",
        },
      ],
      value: "",
      dialogVisible: false,
      frequency: "", //频次：1：高频、2：低频、3：睡眠
      frName: "",
    };
  },
  created() {
    this.getPrivateVipList();

  },
  methods: {
    selectable(row, rowIndex) {
      return !this.checkedAll;
    },
    indexMethod(index) {
      index = (index + 1) + (this.current - 1) * this.size
      return index
    },
    indexCdbqMethod(index) {
      index = (index + 1) + (this.soliCurrent - 1) * this.soliSize
      return index
    },
    getRowKeys(row) {
      return row.cuid;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getPrivateVipList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getPrivateVipList();
    },
    soliHandleSizeChange(val) {
      this.soliSize = val;
      this.handleNodeClick(this.frequency);
    },
    soliHandleCurrentChange(val) {
      this.soliCurrent = val;
      this.handleNodeClick(this.frequency);
    },
    selectItem(list, row) {
      let selected = list.length && list.indexOf(row) !== -1;
      if (list.length === 0) {
        const ids = this.tableData.map((i) => {
          return i.cuid
        })
        for (var i = 0; i < this.checkList.length; i++) {
          if (ids.indexOf(this.checkList[i].cuid) > -1) {
            this.checkList.splice(i, 1)
            i--
          }
        }
      }
      if(!selected) {//取消选中时，把checkList里的那条也删掉
        let index = this.checkList.indexOf(row)
        if (index > -1) {
          this.checkList.splice(index, 1)
        }
      }
      const arr = [...list, ...this.checkList]
      this.checkList = Array.from(new Set(arr))
    },
    selectAll(selection){

      if (selection.length === 0) {
        const ids = this.tableData.map((i) => {
          return i.cuid
        })
        for (var i = 0; i < this.checkList.length; i++) {
          if (ids.indexOf(this.checkList[i].cuid) > -1) {
            this.checkList.splice(i, 1)
            i--
          }
        }
      }
      const arr = [...selection, ...this.checkList]
      this.checkList = Array.from(new Set(arr))
    },
     toggleSelection(){
       if(this.checkedAll) {//全选了所有页
         this.$nextTick(()=>{
           this.changeCheckAll(this.checkedAll);
         })
       } else {//未全选所有页
         this.$nextTick(()=> {
           this.checkList.forEach(row1 =>{
             for(let i = 0;i < this.tableData.length;i++){
               if(row1.cuid === this.tableData[i].cuid){
                 this.$refs.multipleTables.toggleRowSelection(this.tableData[i], true);
               }
             }
           })
         });
       }
      // this.$nextTick(()=> {
      //   this.checkList.forEach(row =>{
      //       this.$refs.multipleTables.toggleRowSelection(this.tableData.find(item =>{
      //         return item.cuid === row.cuid
      //       }),true);
      //   });
      // })

    },
    handleSelectionChange(array) {
      this.multipleSelection = array;
     /* if(this.checkedAll && this.soliSize !== array.length) {//全选所有页了，但是取消某一个了，则全选所有页失效
        this.checkedAll = false;
      } else/!* if(!this.checkedAll && this.soliSize === array.length)*!/ {//全选所有页了，取消某一个又选上了，则全选所有页依然生效
        this.checkedAll = true;
      }*/
      if (array.length > 0) {
        this.choiceDisabled = false;
      } else {
        this.choiceDisabled = true;
      }
    },
    //改变全选所有复选框
    changeCheckAll(val) {
      if (val) {
        // 全选选中时当前页所有数据选中
        this.tableData.forEach(row => {
          if (row) {
            this.$refs.multipleTables.toggleRowSelection(row, true);
          }
        });
      } else {
        this.$refs.multipleTables.clearSelection();
        this.checkList = [];
      }
    },
    async handleNodeClick(soliFlag) {
      this.frequency = soliFlag;
      if (soliFlag === "高频会员") {
        this.cdbqLoading = true;
        await api.getHighFrequencyList(this.soliCurrent,this.soliSize).then((res) => {
          this.tableData = res.data.data.result.records;
          this.soliSize = res.data.data.result.size;
          this.soliTotal = res.data.data.result.total;
        }).finally(() => {
          this.cdbqLoading = false;
        });
        this.frName = "高频";
      } else if (soliFlag === "低频会员") {
        this.cdbqLoading = true;
        await api.getLowFrequencyList(this.soliCurrent,this.soliSize).then((res) => {
          this.tableData = res.data.data.result.records;
          this.soliSize = res.data.data.result.size;
          this.soliTotal = res.data.data.result.total;
        }).finally(() => {
          this.cdbqLoading = false;
        });
        this.frName = "低频";
      } else if (soliFlag === "睡眠会员") {
        this.cdbqLoading = true;
        await api.getSleepList(this.soliCurrent,this.soliSize).then((res) => {
          this.tableData = res.data.data.result.records;
          this.soliSize = res.data.data.result.size;
          this.soliTotal = res.data.data.result.total;
        }).finally(() => {
          this.cdbqLoading = false;
        });
        this.frName = "睡眠";
      }
      //this.searchForm.region = data
      this.toggleSelection();
    },
    lookup() {
      this.getPrivateVipList();
    },
    getPrivateVipList() {
      this.tableLoading = true;
      api.getPrivateVipList(this.current,this.size, this.frequency).then((res) => {
        this.privateData = res.data.data.result.records;
        this.size = res.data.data.result.size;
        this.total = res.data.data.result.total;
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    dialog() {
      if (this.value === "") {
        this.$notify({
          title: "选择信息",
          message: "请选择标签信息",
          type: "error",
          position: "bottom-right",
        });
        return;
      }
      this.soliCurrent = 1;
      this.dialogVisible = true;
      this.handleNodeClick(this.frequency);
    },
    onSubmit() {
      let pvip_name = [];
      for (var i = 0; i < this.checkList.length; i++) {
        pvip_name.push(this.checkList[i].pvip_name);
      }
      var frequency = "";
      if (this.frequency === "高频会员") {
        frequency = "高频";
      } else if (this.frequency === "低频会员") {
        frequency = "低频";
      } else if (this.frequency === "睡眠会员") {
        frequency = "睡眠";
      }
      //console.log(pvip_name.join());
      this.$confirm(
        this.checkedAll ? "是否确认对所有用户打" + frequency + "标签？" : "是否确认对【" + pvip_name.join() + "】用户打" + frequency + "标签？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "del-model",
          type: "warning",
        }
      ).then(() => {
        this.cdbqLoading = true;
        let params = {
          multipleSelection: this.checkList,
          frequency: this.frequency,
          checkedAll: this.checkedAll
        };
        api.insertSoilBySolidifiaction(params).then((res) => {
          if (res.data.data.result) {
            this.$notify({
              title: "提示信息",
              message: "操作成功",
              type: "success",
              position: "bottom-right",
            });
            this.dialogVisible = false;
            this.getPrivateVipList();
            this.checkList = [];
            this.checkedAll = false;
          }
        }).finally(() => {
          this.cdbqLoading = false;
        });
      });
    },
  },
};
</script>

<style scoped>
.cdbq-dialog /deep/ .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "当页";
  color: #606266;
  font-weight: 500;
  margin-left: 10px;
  font-size: 14px;
}
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
.circle {
  border-radius: 80%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.imageName {
  position: absolute;
  top: 10px;
  margin-left: 12%;
  /* margin-top: 10px; */
}
.nickname {
  margin: 20px 0;
}
</style>
