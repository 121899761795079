import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    getPrivateVipList(current,size,soliFlag){
        return getRequest(`/solidifiaction/getPrivateVipList?current=${current}&soliFlag=${soliFlag}&size=${size}`)
    },
    //高频
    getHighFrequencyList(current,size){
        return getRequest(`/solidifiaction/getHighFrequencyList?current=${current}&size=${size}`)
    },
    //低频
    getLowFrequencyList(current,size){
        return getRequest(`/solidifiaction/getLowFrequencyList?current=${current}&size=${size}`)
    },
    //睡眠
    getSleepList(current,size){
        return getRequest(`/solidifiaction/getSleepList?current=${current}&size=${size}`)
    },
    insertSoilBySolidifiaction(data){
        return postRequest(`/solidifiaction/insertSoil`,data)
    },
    insertSoil(data, checkedAll, params, openTime, pVipLevel){
        let pvipCards = "";
        let pvipName = "";
        let pvipNickname = "";
        let pvipPhone = "";
        let openStartDate = "";
        let openEndDate = "";
        if(params.pvipCards != undefined){
            pvipCards = params.pvipCards;
        }
        if(params.pvipName != undefined){
            pvipName = params.pvipName;
        }
        if(params.pvipNickname != undefined){
            pvipNickname = params.pvipNickname;
        }
        if(params.pvipPhone != undefined){
            pvipPhone = params.pvipPhone;
        }
        if(openTime != null && openTime != ''){
            openStartDate = openTime[0]
            openEndDate = openTime[1]
        }
        data.pvipCards = pvipCards;
        data.pvipName = pvipName;
        data.pvipNickname = pvipNickname;
        data.pvipPhone = pvipPhone;
        data.pVipLevel = pVipLevel;
        data.openStartDate = openStartDate;
        data.openEndDate = openEndDate;

        data.checkedAll = checkedAll;
        return postRequest(`/solidifiaction/insertSoil`,data)
    },
    eliminate(data, checkedAll, params, openTime, pVipLevel){
        let pvipCards = "";
        let pvipName = "";
        let pvipNickname = "";
        let pvipPhone = "";
        let openStartDate = "";
        let openEndDate = "";
        if(params.pvipCards != undefined){
            pvipCards = params.pvipCards;
        }
        if(params.pvipName != undefined){
            pvipName = params.pvipName;
        }
        if(params.pvipNickname != undefined){
            pvipNickname = params.pvipNickname;
        }
        if(params.pvipPhone != undefined){
            pvipPhone = params.pvipPhone;
        }
        if(openTime != null && openTime != ''){
            openStartDate = openTime[0]
            openEndDate = openTime[1]
        }
        data.pvipCards = pvipCards;
        data.pvipName = pvipName;
        data.pvipNickname = pvipNickname;
        data.pvipPhone = pvipPhone;
        data.pVipLevel = pVipLevel;
        data.openStartDate = openStartDate;
        data.openEndDate = openEndDate;

        data.checkedAll = checkedAll;
        return postRequest(`/solidifiaction/eliminate`,data)
    }
}
